<template>
  <div class="container mt-4">
    <v-progress-circular
      v-if="loading && !document"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div v-if="!loading && document">
      <div class="container row">
        <router-link :to="`/documents/${document.id}/general`">
          <button class="back-icon mr-4"></button>
        </router-link>
        <h2 class="pt-2 semibold-font">{{ $t('case_history') }}</h2>
      </div>

      <div class="row container d-none d-lg-flex">
        <h4 class="year_h4">{{document.attributes['year'] }}</h4>
        <h4 class="product_h4 ml-4">{{document.attributes['product'] }}</h4>
        <div class="symbol_div ml-4">{{document.attributes['symbol'] }}</div>
      </div>
      <div class="row d-flex d-lg-none mt-0 pt-0 pb-0">
        <v-col cols="9">
          <h4 class="year_h4">
            <span>{{document.attributes['year'] }}</span>
            <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
          </h4>
        </v-col>
        <v-col cols="3" class="text-right">
          <div class="symbol_div">{{document.attributes['symbol'] }}</div>
        </v-col>
      </div>

      <v-container class="pa-0" v-if="display_timeline">
        <v-row class="timeline mt-6">
          <v-col cols="2" class="mr-lg-2 mr-4 mr-lg-0" :class="currentStatus.status_name == timeline.name ? null : uniqStatusesNames.includes(timeline.name) || currentStatus.timeline_position > timeline.timeline_position ? 'finished-status' + (index + 1) : 'incoming-status'" v-for="(timeline, index) in timelines" :key="index">
            <v-container class="pa-0">
              <v-row>
                <v-col cols="8" lg="4">
                  <v-icon v-if="(currentStatus.status_name == timeline.name || !uniqStatusesNames.includes(timeline.name)) && currentStatus.timeline_position <= timeline.timeline_position" medium class="mr-2 timeline-circle-icon">
                    {{ timeline.icon_name }}
                  </v-icon>
                  <v-icon v-if="(currentStatus.status_name != timeline.name && uniqStatusesNames.includes(timeline.name)) || currentStatus.timeline_position > timeline.timeline_position" medium class="mr-2 timeline-circle-icon">
                    fas fa-check
                  </v-icon>
                  <p class="timeline-circle-icon-name d-none d-lg-block mt-2 semibold-font">{{ timeline.name }}</p>
                </v-col>
                <v-col v-if="index != 4" cols="3" lg="8" class="timeline-col ml-1 ml-lg-0">
                  <hr :class="'timeline-line' + (index + 1)">
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <!-- DESKTOP -->
      <v-expansion-panels class="mt-10 mb-12 mb-lg-2 d-none d-lg-block" v-model="panel" multiple>
        <v-expansion-panel
          v-for="(uniq_status, index) in uniq_statuses"
          :key="index"
        >
          <v-expansion-panel-header>
            <h2 v-if="index == 0" class="semibold-font">
              <v-icon medium class="mr-2">
                {{ uniq_status.icon_name }}
              </v-icon>
              {{ uniq_status.status_name }}
            </h2>
            <h2 v-if="index != 0" class="semibold-font">
              <v-icon medium class="mr-2">
                far fa-check-circle
              </v-icon>
              {{ uniq_status.status_name }}
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ml-6" v-if="index == 0">
            <tr class="mb-4" v-for="(step, index) in uniq_status.steps" :key="step.id">
              <td width="4%">
                <v-icon v-if="index == 0" small class="current-step">far fa-clock</v-icon>
                <v-icon v-if="index != 0" small class="inactive-step">far fa-check-circle</v-icon>
              </td>
              <td width="95%">
                <h3 :class="index == 0 ? 'current-step' : 'inactive-step'" class="semibold-font">{{ step.step_name }}</h3>
                <p :class="index != 0 ? 'inactive-step' : null" class="mt-2">
                  {{ step.step_description }}
                </p>
              </td>
              <td :class="index != 0 ? 'inactive-step' : null">
                {{ $date(step['created_at']).format('DD.MM.YYYY') }}
              </td>
            </tr>
          </v-expansion-panel-content>
          <v-expansion-panel-content class="ml-6'" v-if="index != 0">
            <tr class="mb-4" v-for="(step) in uniq_status.steps" :key="step.id">
              <td width="4%">
                <v-icon small class="inactive-step">far fa-check-circle</v-icon>
              </td>
              <td width="95%">
                <h3 class="semibold-font inactive-step">{{ step.step_name }}</h3>
                <p class="mt-2 inactive-step">
                  {{ step.step_description }}
                </p>
              </td>
              <td class="inactive-step">
                {{ $date(step['created_at']).format('DD.MM.YYYY') }}
              </td>
            </tr>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- MOBILE -->
      <v-expansion-panels class="mt-10 mb-12 mb-lg-2 d-block d-lg-none" v-model="panel" multiple>
        <v-expansion-panel
          v-for="(uniq_status, index) in uniq_statuses"
          :key="index"
        >
          <v-expansion-panel-header>
            <h2 v-if="index == 0" class="semibold-font">
              <v-icon medium class="mr-2">
                {{ uniq_status.icon_name }}
              </v-icon>
              {{ uniq_status.status_name }}
            </h2>
            <h2 v-if="index != 0" class="semibold-font">
              <v-icon medium class="mr-2">
                far fa-check-circle
              </v-icon>
              {{ uniq_status.status_name }}
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ml-6" v-if="index == 0">
            <tr class="mb-4" v-for="(step, index) in uniq_status.steps" :key="step.id">
              <td width="10%">
                <v-icon v-if="index == 0" small class="current-step">far fa-clock</v-icon>
                <v-icon v-if="index != 0" small class="inactive-step">far fa-check-circle</v-icon>
              </td>
              <td width="90%">
                <v-row class="mt-3">
                  <v-col cols="7">
                    <h3 :class="index == 0 ? 'current-step' : 'inactive-step'" class="semibold-font">{{ step.step_name }}</h3>
                  </v-col>
                  <v-col cols="4">
                    <div class="pl-1" :class="index != 0 ? 'inactive-step' : null">{{ $date(step['created_at']).format('DD.MM.YYYY') }}</div>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div :class="index != 0 ? 'inactive-step' : null">
                      {{ step.step_description }}
                    </div>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </v-expansion-panel-content>
          <v-expansion-panel-content class="ml-6" v-if="index != 0">
            <tr class="mb-4" v-for="(step) in uniq_status.steps" :key="step.id">
              <td width="10%">
                <v-icon small class="inactive-step">far fa-check-circle</v-icon>
              </td>
              <td width="90%">
                <v-row class="mt-3">
                  <v-col cols="7">
                    <h3 class="semibold-font inactive-step">{{ step.step_name }}</h3>
                  </v-col>
                  <v-col cols="4">
                    <div class="inactive-step pl-1">{{ $date(step['created_at']).format('DD.MM.YYYY') }}</div>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="inactive-step">
                      {{ step.step_description }}
                    </div>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'History',
  data () {
    return {
      uniq_statuses: [],
      timelines: [],
      loading: true,
      display_timeline: true,
      panel: [0]
    }
  },
  computed: {
    ...mapGetters(['document']),

    currentStatus () {
      return this.uniq_statuses[0]
    },

    uniqStatusesNames () {
      return this.uniq_statuses.map(uniqStatus => uniqStatus.status_name)
    }
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
    this.$http.get(`documents/${this.$route.params.documentId}/history`)
      .then(response => {
        this.uniq_statuses = response.data.steps
        this.timelines = response.data.timeline
        this.display_timeline = response.data.display_timeline
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .current-step {
    color: #F26822 !important;

    @media only screen and (max-width: 1263px) {
      word-break: normal;
    }
  }

  .inactive-step {
    color: #B4B4B4 !important;

    @media only screen and (max-width: 1263px) {
      word-break: normal;
    }
  }

  .v-expansion-panels {
    z-index: unset !important;

    .v-item--active {
      background: white !important;
    }
  }

  .v-expansion-panel:not(:first-child)::after {
    border-top: none !important;
  }

  .v-expansion-panel {
    border-radius: 10px !important;
    background: transparent !important;
  }

  .timeline-circle-icon {
    display: inline-block;
    padding: 0.5em 0.6em;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    border: 2px solid #707070;

    @media only screen and (max-width: 1263px) {
      width: 130%;
      height: 38px;
      width: 38px;

      .col {
        padding: 0px;

        .v-icon:before {
          font-size: 20px !important;
        }
      }
    }
  }

  .timeline-circle-icon-name {
    font-size: 14px;
    width: 50px;
  }

  .timeline {
    position: relative;
    margin-left: 0 !important;

    @media only screen and (max-width: 1263px) {
      width: 100%;
      margin-left: -20px;

      .col {
        padding: 0px;
      }

      .timeline-col {
        padding: 0 !important;
      }

      .col:last-child {
        margin-right: -10px !important;
      }
    }

    .finished-status2, .finished-status5 {
      color: #b4b4b4;

      .timeline-line2, .timeline-line5 {
        border: 2px solid #F57921;
        background: #F57921;
      }

      i {
        color: white !important;
        background-color: #F57921 !important;
        border: none;
      }
    }

    .finished-status1, .finished-status4 {
      color: #b4b4b4;

      .timeline-line1, .timeline-line4 {
        border: 2px solid #f29123;
        background: #f29123;
      }

      i {
        color: white !important;
        background-color: #f29123 !important;
        border: none;
      }
    }

    .finished-status3 {
      color: #b4b4b4;

      .timeline-line3 {
        border: 2px solid #d2116e;
        background: #d2116e;
      }

      i {
        color: white !important;
        background-color: #d2116e !important;
        border: none;
      }
    }

    .incoming-status {
      color: #b4b4b4;

      i {
        color: #b4b4b4 !important;
        border: 2px solid #b4b4b4;
      }
    }
  }

  .timeline-line1, .timeline-line2, .timeline-line3, .timeline-line4  {
    margin-top: 20px;
    border: 2px solid #c1c1c1;
    border-radius: 5px;

    @media only screen and (max-width: 1263px) {
      margin-top: 30px;
    }
  }

  .timeline-line5 {
    border: 0;
  }

  @media only screen and (max-width: 1263px) {
    .v-expansion-panel-content__wrap {
      padding: 0 0 16px !important;

      .v-icon {
        margin-right: 5px;
      }
    }
  }

</style>
